<template>
  <component :is="tag" :class="classes.heading">
    {{ text }}
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { getHeadingClasses } from './utils/getHeadingClasses.js'

interface HeadingProps {
  text: string

  level?: '1' | '2' | '3'
  tagName?: string
  size?: 'sm' | 'md' | 'lg'
  lineClamp?: '1' | '2' | '3' | '4' | '5' | '6' | 'none'
}

const props = withDefaults(defineProps<HeadingProps>(), {
  size: 'md',
  lineClamp: 'none',
  level: '2',
  tagName: undefined,
})

const classes = computed(() => {
  return getHeadingClasses({ size: props.size, lineClamp: props.lineClamp })
})

const tag = computed(() => {
  if (props.tagName) {
    return props.tagName
  }

  if (props.level === '1') {
    return 'h1'
  }

  if (props.level === '2') {
    return 'h2'
  }

  return 'h3'
})
</script>

<script lang="ts">
export default {
  name: 'VtHeading',
}
</script>
