<template>
  <label :class="classes.container" :for="id">
    <input
      :id="id"
      ref="inputRef"
      v-model="innerValue"
      type="checkbox"
      :class="classes.input"
      v-bind="$attrs"
    />
    <div :class="classes.content">
      <span :class="classes.label">
        {{ labelText }}
      </span>
      <span v-if="helpText" :class="classes.help">
        {{ helpText }}
      </span>
    </div>
  </label>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { v4 as uuid } from 'uuid'

import { getCheckboxClasses } from './utils/getCheckboxClasses'

interface InputProps {
  labelText: string
  helpText?: string
  modelValue?: boolean
  id?: string
}

const props = withDefaults(defineProps<InputProps>(), {
  id: () => {
    return `i${uuid()}`
  },
  helpText: '',
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const inputRef = ref<HTMLInputElement>()

defineExpose({ input: inputRef })

const classes = computed(() => {
  return getCheckboxClasses()
})

const innerValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})
</script>

<script lang="ts">
export default {
  name: 'VtCheckbox',
  inheritAttrs: false,
}
</script>
