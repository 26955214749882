import { defineComponentClasses } from '@visiontree/vue-ui'

interface Structure {
  container: never
  input: never
  content: never
  label: never
  help: never
}

type Options = Record<never, never>

export const getCheckboxClasses = defineComponentClasses<Options, Structure>({
  classes: {
    container: 'flex gap-4',
    input:
      'focus:ring-3 mt-0.5 h-5 w-5 rounded border-neutral-300 bg-neutral-100 text-primary-500 focus:ring-primary-300 focus:ring-offset-0 dark:border-neutral-600 dark:bg-neutral-700',
    content: 'flex flex-col',
    label: 'text-base font-medium text-neutral-900 dark:text-neutral-50',
    help: 'text-xs text-neutral-700 dark:text-neutral-300',
  },
})
