import { defineComponentClasses } from '@visiontree/vue-ui'

interface Structure {
  heading: never
}

interface Options {
  size?: 'sm' | 'md' | 'lg'
  lineClamp?: '1' | '2' | '3' | '4' | '5' | '6' | 'none'
}

export const getHeadingClasses = defineComponentClasses<Options, Structure>({
  classes: {
    heading: 'font-bold',
  },
  defaultVariants: {
    size: 'md',
    lineClamp: 'none',
  },
  variants: {
    size: {
      sm: {
        heading: 'text-base',
      },
      md: {
        heading: 'text-lg',
      },
      lg: {
        heading: 'text-2xl',
      },
    },
    lineClamp: {
      '1': {
        heading: 'line-clamp-1',
      },
      '2': {
        heading: 'line-clamp-2',
      },
      '3': {
        heading: 'line-clamp-3',
      },
      '4': {
        heading: 'line-clamp-4',
      },
      '5': {
        heading: 'line-clamp-5',
      },
      '6': {
        heading: 'line-clamp-6',
      },
      none: {
        heading: 'line-clamp-none',
      },
    },
  },
})
