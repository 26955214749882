import { defineComponentClasses } from '@visiontree/vue-ui'

interface Structure {
  container: never
  select: never
  label: never
}

type Options = {
  size?: 'sm' | 'md'
}

export const getSelectClasses = defineComponentClasses<Options, Structure>({
  classes: {
    container: 'relative',
    select:
      'w-full rounded border border-neutral-400 bg-neutral-50 text-base focus-visible:!outline-none focus-visible:ring-4 focus-visible:ring-primary-300 dark:border-neutral-600 dark:bg-neutral-700',
    label:
      'absolute left-3 top-0 line-clamp-1 text-neutral-700 dark:text-neutral-300',
  },
  defaultVariants: {
    size: 'md',
  },
  variants: {
    size: {
      sm: {
        select: 'pb-0 pt-4',
        label: 'text-xs leading-relaxed',
      },
      md: {
        select: 'pb-3 pt-6',
        label: 'text-sm leading-loose',
      },
    },
  },
})
