<template>
  <div :class="classes.container">
    <label :class="classes.label" :for="id">{{ labelText }}</label>
    <select
      :id="id"
      ref="selectRef"
      v-model="innerValue"
      :class="classes.select"
      v-bind="$attrs"
    >
      <slot>
        <template v-for="option in normalizedOptions" :key="option.text">
          <optgroup v-if="'options' in option" :label="option.text">
            <option
              v-for="childOption in option.options"
              :key="childOption.value"
              :value="childOption.value"
            >
              {{ childOption.text }}
            </option>
          </optgroup>
          <option v-else :key="option.value" :value="option.value">
            {{ option.text }}
          </option>
        </template>
      </slot>
    </select>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { v4 as uuid } from 'uuid'

import { getSelectClasses } from './utils/getSelectClasses'

type SelectOptionValue = string

interface SelectOption {
  text: string
  value: SelectOptionValue
}

type ArrayOrReadonlyArray<T> = Array<T> | ReadonlyArray<T>

interface SelectOptionGroup {
  text: string
  options: ArrayOrReadonlyArray<SelectOption | SelectOptionValue>
}

interface SelectProps {
  labelText: string
  modelValue?: SelectOptionValue
  id?: string
  size?: 'sm' | 'md'
  options?: ArrayOrReadonlyArray<
    SelectOptionValue | SelectOption | SelectOptionGroup
  >
}

const props = withDefaults(defineProps<SelectProps>(), {
  id: () => {
    return `i${uuid()}`
  },
  options: () => {
    return []
  },
  modelValue: undefined,
  size: 'md',
})

const emit = defineEmits<{
  (e: 'update:modelValue', value?: SelectOptionValue): void
}>()

const selectRef = ref<HTMLSelectElement>()

defineExpose({ select: selectRef })

const classes = computed(() => {
  return getSelectClasses({ size: props.size })
})

const normalizeOption = (
  option: SelectOption | SelectOptionValue,
): SelectOption => {
  if (typeof option !== 'object') {
    return {
      text: option,
      value: option,
    }
  }

  return option
}

const normalizedOptions = computed(() => {
  return props.options.map((option) => {
    if (typeof option !== 'object' || !('options' in option)) {
      return normalizeOption(option)
    }

    return {
      text: option.text,
      options: option.options.map(normalizeOption),
    }
  })
})

const innerValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>
